import jwt_decode from "jwt-decode";

export const getCookie = (name) => {

    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    // return decodeURIComponent('s:b2d7684d-8907-4512-94d0-da1ce6160df2.HvAeyD3vMf+vUo6/+Iv+qS+NW2G2LJASvBMHhZjGSok');
    if (parts.length === 2) return decodeURIComponent(parts.pop().split(';').shift());
  }
  export  const getTokenData=()=> {
    const token = localStorage.getItem('token') 
    if(token){
       const decodedToken  = jwt_decode(token);
       return decodedToken;
     }
    return false
 }
// export const REACT_APP_API_Realm = 'A1bPAGRUcGsVgupH09R3FLIblKXww60vCKGKnpJVWdCLmdMSzs12ElWCheXWG5XU'
// export const REACT_APP_API_RealamId = 'accounts-live-sdpwm'
export const REACT_APP_API_Realm = 'Ve9dvUzJyMvbT1rkjmTRalt2No5wQhge5J7y0ZQWD6K7dHn9lqFkCgDE9y49X7if'
export const REACT_APP_API_RealamId =  'accounts-dev-ctclr'