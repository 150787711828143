import './App.css';
import StepsOne from './steps-1';

function App() {
  return (
    <div className="App">
      <StepsOne />
    </div>
  );
}

export default App;
