import { React, ReactDOM, useEffect, useState } from "react";
import bots from './botDetails/en.json'
import axios from "axios";
import loader from "./img/ZZ5H.gif"
import md5 from 'md5';
import * as Realm from "realm-web";
import { getCookie, getTokenData } from './helper.js/login';
import { REACT_APP_API_RealamId, REACT_APP_API_Realm } from './helper.js/login';
const credentials = Realm.Credentials.apiKey(REACT_APP_API_Realm);
const realApp = new Realm.App({ id: REACT_APP_API_RealamId });

const search = window.location.search;
const params = new URLSearchParams(search);
const searchText = params.get('id');
const searchType = params.get('type');
const Steps3 = (props) => {
  const [botId, setBotId] = useState(0)
  const [filteredBots, setFilterBots] = useState(bots.botList)
  const [confirmation, setConfirm] = useState(false)
  const [responseBot, setResponse] = useState()
  const [bypasss, setByPasss] = useState()
  const [user, setUser] = useState({})
  const [onemore, setOnemore] = useState(false)
  const [name, setName] = useState(' ')
  const [botListView,setBotList] = useState(false)
  const [loading, setIsLoading]  = useState(true)
 const  [error , setError] = useState(false)

  useEffect(() => {
		setTimeout(() => {
		  setIsLoading(false)
		} , 5000)
	  },[])
  useEffect(() => {
    if (searchType == "chatbot") {
      setByPasss(true)
    } else {
      setByPasss(false)
    }
    checkcommonlogin()

  }, [])

  // useEffect(() => { checkcommonlogin() }, [bypasss])

  useEffect(() => { createdVisitedLog() }, [user])

  const checkcommonlogin = async () => {
    // console.log("APYYUID");
    try{
    const uid = getCookie("APPYID");
    if (uid) {
      let login_type = "owner";

      let realmAgent = realApp.currentUser
      if (!realmAgent) {
        realmAgent = await realApp.logIn(credentials);
      }
      const currentLoggedInUser = await realmAgent.functions.getHelpDeskSession(md5(uid), login_type);
      const userinfo = JSON.parse(currentLoggedInUser.userdata)
      // console.log("kkkkkkk", currentLoggedInUser.userdata);
      
        setUser(userinfo.userdata)
    } else {
    }
  } catch(err){
   console.log("errr",err.message);
  }
  };

  const handleClick = (id) => {
    setBotId(id)
  }

const createBot = (data2) => {
    setOnemore(true)
    // console.log(data2);
    if(data2.table_alias!=undefined){
    var data = JSON.stringify({
      "email": data2.email,
      "productId": data2.owner_id,
      "createnewbot": true,
      "botName": name?name:'sample',//data2.company ? data2.company : "asxasx",
      "botType": botId,
      "tablealias": data2.table_alias,
      "owner_id": data2.owner_id,
      "appyChat":true
    });

    var config = {
      method: 'post',
      url:'https://us-central1-appycx-beta.cloudfunctions.net/createBotAndAcc',
      // url:'https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/createBotAndAcc',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data,"===================");
        if(response.data.statusCode==200){
        setResponse(response.data.data)
        console.log(JSON.stringify(response.data));
        setConfirm(true);
        }else{
          setConfirm(true);
        setError(true)
        }
      })
      .catch(function (error) {
        console.log(error);        
        setConfirm(true);
        setError(true)
      });
    }else{
      setConfirm(true);
      setError(true)
    }
  }

  const handleSearch = (text) => {
    let serach = text.target.value
    let searchBots = bots.botList.filter((obj) => {
      return obj.button.toLowerCase().includes(serach.toLowerCase())
    })
    setFilterBots(searchBots)
  }

  const createdVisitedLog = () => {
    user.appType = "Chatbot"

    // console.log("props.user?.userdataprops.user?.userdata", user);
    if (user?.first_name) {
      var config = {
        method: 'post',
        url:'https://us-central1-appycx-beta.cloudfunctions.net/createLog',
        // url: 'https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/createLog',
        headers: {
          'Content-Type': 'application/json'
        },
        data: user
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  const goBack = () => {
    let type= bypasss?"chatbot":"livechatChatbot"
    const backtoParent = {
      "method": "chatWidgetDetails",
      "appId":  atob(props.id),
      "agentId": responseBot.agent_id,
      "botUserId": responseBot.tablealias,
      "botId": responseBot.bot_id,
      "type": type,
      "botType": botId,
      "connected": 1,
      "urlKey": props.id,
      "url": "https://stagingchatbot.pbodev.info/cx/Chatbot",
      "data": responseBot

    }


    var config = {
      method: 'post',
      url: 'https://72snappy.pbodev.info/webservices/integration.php',
      headers: {
        'Content-Type': 'application/json'
      },
      data: backtoParent
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data));
        window.location.replace('https://stagingchatbot.pbodev.info/cx/Chatbot')
      })
      .catch(function (error) {
        console.log(error);
      }); 
  }

  const handleBack = () => {
    // console.log("handleBack");
  }
  const handleChange = (e) => {
    let name = e.target.value
    // console.log(e.target.value,'e.target.valuehandleChange',e.key);
    setName(name.trim())
    if (e.key=="Enter"&& name.trim()!=''){
      if(bypasss){
      setBotList(true)
      }else{
        setBotList(true); 
        setByPasss(false);
        setBotList(!botListView)
        createBot(user)
      }
    }
  }
  const handleButton = (e) =>{
  if(bypasss){
    setBotList(true);
     setByPasss(true);
     setBotList(!botListView)
  }else{
    setBotList(true); 
    setByPasss(false);
    setBotList(!botListView)
    createBot(user)
  }
  }

  return (
   loading?<div className="botPop"><div className="loaderView"><img src={loader} /></div></div>:
    <>
    {!botListView?
    <div className="createChatBoatStepBy step4personalitys">
				{/* <p className="already-acc">
          <span>Go to Dashboard</span>
        </p> */}

					<div className="container">
						<div className="logoSectioneasyStep">
							<a href="#">
                <img src={require("../src/logo_noFontn.png")} />
              </a>
						</div>

					</div>
					<div className="centerLavelSteps">
						<div className="step1TitleHeadings d-flex justify-content-center align-items-baseline" style={{gap:'5px'}}>
							<h2 className="stepHeading">Enter your bot name</h2>
							<h3 className="text-center">(This can be changed later)</h3>
						</div>
						<div className="wrapper">
							<div className="swiper-container swiperContainerCustome">
								<div className="swiper-wrapper swiperFormSection">
									<div className="swiper-slide">
                  <div className="wrapperStep">
				<div className="botName-view">
					<div>
						<input placeholder={"Enter Bot Name"}  onChange={(e)=>handleChange(e)} autoFocus={true}/>
						{!name&&<div className="Loginwarning">
              <img src={require("../src/danger.png")} />
              <span>Fields should not be blank</span>
            </div>} 
					</div>
				</div>
			</div>
					</div>
					</div>

						<div className="footerStepbyStep">
							<div
								className="review-section">
								<h4 className="review-heading">
									Highly rated by thousands of customers all over the world
								</h4>
								<div className="review-companies">
									<div className="reviewcompanieswrapper">
										<div className="reviewcompaniesimg">
											<a
												href="https://www.capterra.com/p/140928/Appy-Pie/"
												target="_blank"
												rel="nofollow noopener"
											>
												<img
													src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/capterra.png"
													width="100"
													height="22"
													alt="Capterra"
													data-lazy-src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/capterra.png"
													className="lazyloaded"
													data-was-processed="true"
												/>
											</a>
										</div>
										<div className="ratingReview">
											<p>
												<span>4.5/</span>5
											</p>
										</div>
										<div className="reviewratingstar">
											<p className="tool-tip">
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>☆</span>
											</p>
										</div>
									</div>
									<div className="reviewcompanieswrapper">
										<div className="reviewcompaniesimg">
											<a
												href="https://www.g2.com/products/appy-pie/reviews"
												target="_blank"
												rel="nofollow noopener"
											>
												<img
													src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/g2-crowd-white1.png"
													width="80"
													height="25"
													alt="G2"
													data-lazy-src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/g2-crowd-white1.png"
													className="lazyloaded"
													data-was-processed="true"
												/>
											</a>
										</div>
										<div className="ratingReview">
											<p>
												<span>4.5/</span>5
											</p>
										</div>
										<div className="reviewratingstar">
											<p className="tool-tip">
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>☆</span>
											</p>
										</div>
									</div>
									<div className="reviewcompanieswrapper">
										<div className="reviewcompaniesimg">
											<a
												href="https://gsuite.google.com/marketplace/app/appy_pie/161113010631"
												target="_blank"
												rel="nofollow noopener"
											>
												<img
													src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/gsuite-marketplace-logo.png"
													width="20"
													height="20"
													alt="Gsuite Marketplace"
													data-lazy-src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/gsuite-marketplace-logo.png"
													className="lazyloaded"
													data-was-processed="true"
												/>
											</a>
										</div>
										<div className="ratingReview">
											<p>
												<span>4.6/</span>5
											</p>
										</div>
										<div className="reviewratingstar">
											<p className="tool-tip">
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>☆</span>
											</p>
										</div>
									</div>
									<div className="reviewcompanieswrapper">
										<div className="reviewcompaniesimg">
											<a
												href="https://www.trustpilot.com/review/www.appypie.com"
												target="_blank"
												rel="nofollow noopener"
											>
												<img
													src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/trustpilot.png"
													width="100"
													height="25"
													alt="Trustpilot"
													data-lazy-src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/trustpilot.png"
													className="lazyloaded"
													data-was-processed="true"
												/>
											</a>
										</div>
										<div className="ratingReview">
											<p>
												<span>4.6/</span>5
											</p>
										</div>
										<div className="reviewratingstar">
											<p className="tool-tip">
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>☆</span>
											</p>
										</div>
									</div>
									<div className="reviewcompanieswrapper">
										<div className="reviewcompaniesimg">
											<a
												href="https://www.getapp.com/development-tools-software/a/appy-pie/"
												target="_blank"
												rel="nofollow noopener"
											>
												<img
													src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/getapp.png"
													width="80"
													height="25"
													alt="Get App"
													data-lazy-src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/getapp.png"
													className="lazyloaded"
													data-was-processed="true"
												/>
											</a>
										</div>
										<div className="ratingReview">
											<p>
												<span>5.0/</span>5
											</p>
										</div>
										<div className="reviewratingstar">
											<p className="tool-tip">
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>☆</span>
											</p>
										</div>
									</div>
									<div className="reviewcompanieswrapper">
										<div className="reviewcompaniesimg">
											<a
												href="https://www.softwareadvice.com/app-development/appy-pie-profile/"
												target="_blank"
												rel="nofollow noopener"
											>
												<img
													src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/softwareadvicelogo-dark.png"
													width="100"
													height="16"
													alt="Software Advice"
													data-lazy-src="https://d2wuvg8krwnvon.cloudfront.net/newui/images/review/softwareadvicelogo-dark.png"
													className="lazyloaded"
													data-was-processed="true"
												/>
											</a>
										</div>
										<div className="ratingReview">
											<p>
												<span>4.6/</span>5
											</p>
										</div>
										<div className="reviewratingstar">
											<p className="tool-tip">
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>★</span>
												<span>☆</span>
											</p>
										</div>
									</div>
								</div>
							</div> 
							
							
						</div> 
					</div>

				</div>
        </div>
        <div className="footerNext">
						<div className="container d-flex align-items-center">
            {
							name.trim()=='' ? <span id="nxtBtn" className="startCustmisingStep1 ml-auto" style={{'cursor':'no-drop'}}>{"Next"}&nbsp;&nbsp;
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 14.103 24.669">
                  <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M21.1,18.525,11.768,9.2a1.763,1.763,0,0,1,2.5-2.49L24.84,17.277a1.759,1.759,0,0,1,.051,2.431L14.273,30.349a1.763,1.763,0,0,1-2.5-2.49Z" transform="translate(-11.251 -6.194)" fill="#fff" />
                </svg>
              </span>
            </span>: 
                     <button id="nxtBtn" className="startCustmisingStep1 ml-auto" onClick={(e)=>{handleButton(e)}}>{"Next"}&nbsp;&nbsp;
                     <span>
                       <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 14.103 24.669">
                         <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M21.1,18.525,11.768,9.2a1.763,1.763,0,0,1,2.5-2.49L24.84,17.277a1.759,1.759,0,0,1,.051,2.431L14.273,30.349a1.763,1.763,0,0,1-2.5-2.49Z" transform="translate(-11.251 -6.194)" fill="#fff" />
                       </svg>
                     </span>
                   </button>}
						</div>
					</div>
        </div>:
      <div className="chatbot" >
        <div>
          <div className="createChatBoatStepBy step4personalitys">
            <div className="container" >
              <div className="logoSectioneasyStep">
                {/* <a href="https://www.appypie.com/chatbot/builder"> */}
                <img src={require('../src/logo_noFontn.png')} />
                {/* </a> */}
              </div>
            </div>
            <div className="centerLavelSteps2">
              <div className="wrapper">
                <div className="swiper-container swiperContainerCustome swiper-container-horizontal">
                  <div className="swiper-wrapper swiperFormSection" >

                    {bypasss ?
                      <><div className="swiper-slide pb-100 swiper-slide-active" >
                        <div className="global">
                          <div className="wrapperStep botDesign">

                            <div className="container">
                              <div className="row mb-3">
                                <div className="col-md-12">
                                  <span className="wrapperStepHadingUpPoints">
                                    <strong>Select a chatbot to start with</strong>
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-xs-12 col-md-7">
                                  <section className="searchSection">
                                    <div className="serchFeilds">
                                      <span className="serchIconC" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.896" height="19.896" viewBox="0 0 19.896 19.896">
                                          <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(1 1)">
                                            <path id="Path_2713" data-name="Path 2713" d="M20.04,12.27A7.77,7.77,0,1,1,12.27,4.5a7.77,7.77,0,0,1,7.77,7.77Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
                                            <path id="Path_2714" data-name="Path 2714" d="M29.2,29.2l-4.225-4.225" transform="translate(-11.718 -11.718)" fill="none" stroke="#767676" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
                                          </g>
                                        </svg>
                                      </span>
                                      {/* <input type="text" placeholder="Search bot" id="searchTextValue" defaultValue /> */}
                                      <input type="text" placeholder="Search bot" onChange={(e) => { handleSearch(e) }} id="searchTextValue" autoFocus={true} />
                                    </div>
                                  </section>
                                  <h4 className="popularBotHeading pb-2">Popular Bots</h4>
                                  <span id="searchError" >(We could not find the bot youre looking for)</span>
                                  <div className="allbotlist scrollDesign pb-0">
                                    {filteredBots.map(bot => {
                                      return (
                                        <div className="selectYourBot">
                                          <div className={`${bot.id==botId?"selct-bot-row botTypeDesign active":"selct-bot-row botTypeDesign" }`} onClick={() => handleClick(bot.id)} id={bot.id} >
                                            {bot.id==5 &&<div className="starAnimaton">
                                              <i className="starA" />
                                              <i className="starA" />
                                              <i className="starA" />
                                            </div>}
                                            <div className="imageLogoIcon">
                                              <img id="logo5" src={require('./botDetails/images/' + `${bot.imagePath}`)} alt="Logo" className="Targetimage" />
                                            </div>
                                            <div className="select-bot-para">
                                              <h4 className="BotTypeNameTitle">{bot.button}</h4>
                                              <p className="threeIconBotaPara">{bot.paraGraphbott}</p>
                                            </div>
                                          </div>
                                        </div>)
                                    })}
                                  </div>
                                </div>
                                <div className="col-xs-12 col-md-5">
                                  {/* <iframe style={{maxHeight: 'calc(580px)', height: '100vh'}} id="previewIFBot" className="widgetNewAccordingSelction" src={`https://chatbot.appypie.com/previewbot.html?tempid=${botId}`}  /> */}
                                  <iframe
                                    id='previewIFBot'
                                    className="widgetNewAccordingSelction"
                                    src={`https://chatwidget.bot/previewnew.html?tempid=${botId}`}
                                    // src={"http://localhost:8080/?tempid=0}
                                    style={{ maxHeight: 'calc(580px - 0px)', height: '100%' }}

                                  />

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="footerNext">
                          <div class="container d-flex align-items-center">
                            <button class="cancelStep1" onClick={() => handleBack(props.cb)}>Back</button>
                            <button class="startCustmisingStep1 ml-auto" onClick={() => createBot(user)}>Save  & Next&nbsp;&nbsp; <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 14.103 24.669">
                                <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M21.1,18.525,11.768,9.2a1.763,1.763,0,0,1,2.5-2.49L24.84,17.277a1.759,1.759,0,0,1,.051,2.431L14.273,30.349a1.763,1.763,0,0,1-2.5-2.49Z" transform="translate(-11.251 -6.194)" fill="#fff"></path>
                              </svg>
                            </span>
                            </button>
                          </div>
                        </div>

                        <div className="swiper-slide swiper-slide-next" >
                          <div className="global" />
                        </div></> : <>
                        
                      </>}
                  </div>

                  <div className="swiper-pagination" />
                  <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                </div>
              </div>

            </div>
          </div>
          <p />
        </div>

        {onemore && confirmation? <div className="botPop">
          <div className="popBody">
            {!error?<>   
            <img src="img/congrats.png" alt="congrats" />
            <h3>Congratulations!</h3>
            <p className="mb-2">You have got 7 days of premium trial for Chatbot.</p>
            <span>Deploy the Chatbot widget on your platform and customize it for better user experience.</span>
            <div className="d-flex mt-4 btn-section justify-content-center">
              <button className="btn btn-primary" onClick={() => { goBack() }}>Go to Chat Dashboard</button>
            </div>
            </>:
            <>
            <h3> Opps!!</h3>
            <p className="mb-2">Something Went Wrong!</p>
            <div className="d-flex mt-4 btn-section justify-content-center">
              <button className="btn btn-primary" onClick={() => {  window.location.reload(); }}>Click here to reload the page</button>
            </div>
            </>}
          </div>
        </div> : onemore && <div className="botPop"><div className="loaderView"><img src={loader} /></div></div>}

      </div>
}
    </>
  );
};

export default Steps3;
