import {React, ReactDOM, useEffect, useState} from 'react';
import Steps2 from './steps-2';
import Steps3 from './steps-3';
import md5 from 'md5';
import axios from 'axios'
import * as Realm from "realm-web";
import { getCookie,getTokenData } from './helper.js/login';
import { REACT_APP_API_RealamId,REACT_APP_API_Realm } from './helper.js/login';
const credentials = Realm.Credentials.apiKey(REACT_APP_API_Realm);
const realApp = new Realm.App({ id:REACT_APP_API_RealamId});

const search = window.location.search;
const params = new URLSearchParams(search);
const searchText = params.get('id');
const searchType = params.get('type');
const StepsOne = ()=>{
	const [InputValue, setValue] = useState('');
	const [loding, setloding] = useState(!true);
	const [state, setState] = useState(searchType);
	const [placeHolder, setPlace] = useState('Enter youe company name!')
    const [btnView, setbtnView] = useState(true);
	const [user, setUser] = useState()
	
	
	useEffect(() => {
		if(searchType!== null){
			checkValue(searchType)
		}	
	  },[]);
		
	  useEffect(()=>{
		if(user!=null)
		{
		GotoSteps()
		}
	  },[user])
  /**
   * Check common login is valid or not
   */
   const checkcommonlogin = async () => {
    const uid = getCookie("APPYID");
    if (uid) {
      let login_type = "owner";
      let realmAgent =   realApp.currentUser
      if(!realmAgent){
          realmAgent = await realApp.logIn(credentials);
      }
      const currentLoggedInUser = await realmAgent.functions.getHelpDeskSession(md5(uid),login_type);
      const userinfo = currentLoggedInUser.userdata
	  setUser(userinfo)
    } else {
		return
    }
  };

	const GotoSteps = ()=>{
		setloding(false);
	}
	const ChangeValue = (e)=>{
		if (e.target.value === ""){
			setbtnView(true)
		}
		else {
			setbtnView(false)
		}
		setValue(e.target.value)
	}
	const checkValue=(e)=>{
		setState(e)
		if(e!='Livechat'){
			setPlace('Enter your bot name!')
		}else{
			setPlace('Enter youe company name!')
		}
	}
	const cb =()=>{
		// console.log("sdbfhfj");
	}
	if(loding){
		return(
			<>
				<div className="main-wraper">
				  <div className="bg-banner">
					 <div className="content-wraper">
					 <div className="logoSectioneasyStep">
						<a href="https://www.appypie.com/chatbot/builder">
						<img src="https://www.appypie.com/chatbot/wp-content/uploads/2023/03/appypie-logo-white.svg" />
						</a>
						</div>
						<h2>Choose the Chat product you would love to use</h2>
						<div className="choose-one d-flex justify-content-center pt-4 pb-4">
						   <label onClick={()=>checkValue('Livechat')} className="check-label mr-4">Livechat
							  <input type="radio" name="radio" defaultChecked/>
							  <span className="checkmark"></span>
						   </label>
						   <label onClick={()=>checkValue('Chatbot')} className="check-label pr-4">Chatbot
							  <input type="radio" name="radio" />
							  <span className="checkmark"></span>
						   </label>
						   <label onClick={()=>checkValue('Chatbot')} className="check-label pr-4">Chatbot with Livechat
							  <input type="radio" name="radio" />
							  <span className="checkmark"></span>
						   </label>
						   
						</div>
						<div className="position-relative">
						   <input onChange={ChangeValue} id="BusinessName" className="type-name" type="text" placeholder={placeHolder}  autoFocus={true} />
						</div>
						{
							btnView ? <span style={{opacity:'0.5', cursor:'default'}} className='btn btn-primary save-btn'>Next</span> : <button id="stepBtn" className="btn btn-primary save-btn" onClick={()=>{GotoSteps('aaaa')}}>Next </button>
						}
					 </div>
				  </div>
			   </div>
			
			
			   </>
				)
	}
	else if(state === 'livechat'){
		return(<Steps2 name ={InputValue} user={user} id ={searchText}/>)
	}
	else {
		return(<Steps3 id ={searchText}/>)
	}
	
}

export default StepsOne;