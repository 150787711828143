import { React, ReactDOM, useEffect, useState } from 'react';
import loader from "./img/ZZ5H.gif"
import md5 from 'md5';
import axios from 'axios'
import * as Realm from "realm-web";
import { getCookie, getTokenData } from './helper.js/login';
import { REACT_APP_API_RealamId, REACT_APP_API_Realm } from './helper.js/login';

import img from './footer-logo/appypie-livechat.svg'
const credentials = Realm.Credentials.apiKey(REACT_APP_API_Realm);
const realApp = new Realm.App({ id: REACT_APP_API_RealamId });
const Steps2 = (props) => {
   const widgetUrl = [
      "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/Icon-md-chatboxes.svg?alt=media&token=f34a2648-18b9-4690-9450-9a9f6cf2b87e",
      "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/apptochat%2FIcon%20ionic-ios-chatbubbles.svg?alt=media&token=da613929-3044-43cd-aa1f-07100e760d1c",
      "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/apptochat%2FIcon%20ionic-md-chatboxes.svg?alt=media&token=29d2e5e5-f0ad-408e-a694-61a90ee83f53",
      "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/apptochat%2Fchatboticn.svg?alt=media&token=697350a3-f862-4aa7-a991-cc5fdbe619d4"
   ]
   const [widget, setWidget] = useState(widgetUrl[0])
   const widgetColor = ['#2580ea', '#733ee5', '#ff6766', '#fb8446', '#ee641d', '#06c7fe', '#c1e7f7', '#09dce4', '#45be58', '#bcedbc']
   const [colorBg, setcolorBg] = useState('#2580ea')
   const [checkIcon, setcheckIcon] = useState(1)
   const [activeBadge, setactiveBadge] = useState(1)
   const [confirmation, setConfirm] = useState(false)
   const [user, setUser] = useState({})
   const [responseBot, setResponse] = useState()
   const [onemore, setOnemore] = useState(false)
   const [companyName ,setCompanyName] = useState(false)
   const [name, setName] = useState(' ')
   const  [error , setError] = useState(false)
  const [loading, setIsLoading]  = useState(true)
   // const [user, setUser] = useState()

   useEffect(() => { 
if(name!=' '|| name!=null)
      checkcommonlogin()
   }, [name])
   useEffect(() => { }, [onemore, colorBg])

   useEffect(() => {
		setTimeout(() => {
		  setIsLoading(false)
		} , 5000)
	  },[])


   /**
    * Check common login is valid or not
    */
   const checkcommonlogin = async () => {
      try{
      const uid = getCookie("APPYID");
      if (uid) {
         let login_type = "owner";

         let realmAgent = realApp.currentUser
         if (!realmAgent) {
            realmAgent = await realApp.logIn(credentials);
         }
         const currentLoggedInUser = await realmAgent.functions.getHelpDeskSession(md5(uid), login_type);
         const userinfo = JSON.parse(currentLoggedInUser.userdata)
         setUser(userinfo.userdata)
         //   GotoSteps()

      } else {
      }
   } catch(err){
      console.log("errr",err.message);
     }
   };
   const createdVisitedLog = () => {
      // var axios = require('axios');
      user.appType = "Livechat"

      if (user?.first_name) {
         var config = {
            method: 'post',
            url: 'https://us-central1-appycx-beta.cloudfunctions.net/createLog',
            headers: {
               'Content-Type': 'application/json'
            },
            data: user
         };

         axios(config)
            .then(function (response) {
               // console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
               console.log(error);
            });
      }
   }

   const CreateLiveChat = (email, name, color) => {
      setOnemore(true)
      if(email){
      createdVisitedLog()
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "email": email,
        "IsAppyChat": true,
        "companyName": name
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch("https://us-central1-reacttest-29b23.cloudfunctions.net/checkUserAppyChat", requestOptions)
      // fetch("https://us-central1-livechat-production.cloudfunctions.net/checkUserAppyChat", requestOptions)
        .then(response => response.json())
        .then(result => {
         setResponse(result)
         setOnemore(true)
         setConfirm(true)
        })
        .catch(error => console.log('error', error));
      }else{
         setConfirm(true);
         setError(true)
      }
   }
   const handleChange = (e) => {
      let name = e.target.value
      if (e.key=="Enter"&& name.trim()!=''){
         setName(name.trim())
         setCompanyName(true)
      }
      else {
      setName(name.trim())
      }
    }

    const goBack = () => {
      console.log(atob(props.id),"atob(props.id)");
      
      const backtoParent = {
        "method": "chatWidgetDetails",
        "appId": atob(props.id),
        "agentId": responseBot.uid,
        "botUserId": responseBot.botId,
        "botId": responseBot.uid,
        "type": "livechat",
        "productId":responseBot.uid,
        "botType": 5,
        "connected": 1,
        "urlKey": props.id,
        "url": "https://chat-staging.pbodev.info/common-session",
        "data": responseBot
  
      }
  
  
      var config = {
        method: 'post',
        url: "https://72snappy.pbodev.info/webservices/integration.php",//'https://snappy.appypie.com/webservices/integration.php',
        headers: {
          'Content-Type': 'application/json'
        },
        data: backtoParent
      };
  
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data.data));
          window.location.replace('https://chat-staging.pbodev.info/common-session')
        })
        .catch(function (error) {
          console.log(error);
        });
      setConfirm(false)
    }


   return (
      loading?<div className="botPop"><div className="loaderView"><img src={loader} /></div></div>:
      <>
      {!companyName?
      <div className="login-container loginBG">
         <div className="loginPage">
            <div className="container">
               <div className="login-header">
                  <img className="loginHeaderLogo" src= {img}alt="logo"/>
               </div>
            </div>
            <div className="container">
               <div className="company-information company-information1">
                  <div className="login-description pb-10 mb-5">
                     <h5>Enter your Company name</h5>
                     <h4>(This can be changed later)</h4>
                  </div>
                  {/* <form method="post" action=""> */}
                     <div className="form-group">
                     <label>Company name </label>
                     <input type="text" className="form-control" placeholder="Enter name" onChange={(e)=>handleChange(e)} onKeyDown={(e)=>{handleChange(e)}}/>
                     </div>
                     <div className="text-center py-8">
                     {name.trim()=='' ? <span className="btn btn-primary px-5 w-200 waves-effect waves-light">Next</span>: 
                     <button  className="btn btn-primary px-5 w-200 waves-effect waves-light"  onClick={()=>{setCompanyName (true)}}>Next</button>
						}
                     </div>
                  {/* </form> */}
               </div>
            </div>
            <div className="footerStepbyStep loginFooterReview">
               <div className="container">
                  <div className="catfeacture-section-2">
                     <h4 className="clicntLogo-heading">Join <strong>7 million +</strong> other businesses:</h4>
                     <ul>
                        <li><img alt="" src={require("./footer-logo/accenture.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/assurant.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/decathlon.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/deloitte.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/escorts.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/loreal.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/mitie.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/nike.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/pinnacle.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/sodexo.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/shangri.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/southwest.png")}/></li>
                        <li><img alt="" src={require("./footer-logo/thehomedepot.png")}/></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
            </div>:


      <div className="main-wraper" >
            <div className="container">
               <div className="logoSectioneasyStep">
                  <img style={{ 'width': '235px' }} src="https://chat.appypie.com/static/media/appypie-livechat.992c6ace.svg" />
               </div></div>
            <div className="bg-banner">
               <div className="container select-bot pt-5">
                  <div className="bot-head d-flex mt-4">
                     {/* <img className="back" src="https://appychatbot.pbodev.info/wp-content/uploads/2022/11/icon-back.png"
                        alt="back icon" /> */}
                     <div>
                        <h2 className="mt-0">Customise your widget</h2>
                        <p className="mt-0"><b>Pro tip</b> : We suggest you to match your widget’s design to your website to
                           create a unified experience</p>
                     </div>
                  </div>

                  <div className="customize-view d-flex justify-content-between flex-wrap px-4 mt-4">
                     <div className="customize-options">
                        <div className="widget-box">
                           <p>Widget badge style</p>
                           <ul className="d-flex">
                              <li><a className={activeBadge === 1 && 'active'} onClick={() => { setWidget(widgetUrl[0]); setactiveBadge(1) }} ><img src={widgetUrl[0]} alt="icon" /></a></li>
                              <li><a className={activeBadge === 2 && 'active'} onClick={() => { setWidget(widgetUrl[1]); setactiveBadge(2) }} ><img src={widgetUrl[1]} alt="icon" /></a></li>
                              <li><a className={activeBadge === 3 && 'active'} onClick={() => { setWidget(widgetUrl[2]); setactiveBadge(3) }} ><img src={widgetUrl[2]} alt="icon" /></a></li>
                              <li><a className={activeBadge === 4 && 'active'} onClick={() => { setWidget(widgetUrl[3]); setactiveBadge(4) }} ><img src={widgetUrl[3]} alt="icon" /></a></li>
                           </ul>
                        </div>

                        <div className="widget-box mt-4 thn-color">
                           <p>Theme color</p>
                           <ul className="d-flex flex-wrap">
                              <li className={checkIcon === 1 && 'rightIconClick'} onClick={() => { setcolorBg(widgetColor[0]); setcheckIcon(1) }}><a></a></li>
                              <li className={checkIcon === 2 && 'rightIconClick'} onClick={() => { setcolorBg(widgetColor[1]); setcheckIcon(2) }}><a className="color-1"></a></li>
                              <li className={checkIcon === 3 && 'rightIconClick'}><a onClick={() => { setcolorBg(widgetColor[2]); setcheckIcon(3) }} className="color-2"></a></li>
                              <li className={checkIcon === 4 && 'rightIconClick'}><a onClick={() => { setcolorBg(widgetColor[3]); setcheckIcon(4) }} className="color-3"></a></li>
                              <li className={checkIcon === 5 && 'rightIconClick'}><a onClick={() => { setcolorBg(widgetColor[4]); setcheckIcon(5) }} className="color-4"></a></li>
                              <li className={checkIcon === 6 && 'rightIconClick'}><a onClick={() => { setcolorBg(widgetColor[5]); setcheckIcon(6) }} className="color-5"></a></li>
                              <li className={checkIcon === 7 && 'rightIconClick'}><a onClick={() => { setcolorBg(widgetColor[6]); setcheckIcon(7) }} className="color-6"></a></li>
                              <li className={checkIcon === 8 && 'rightIconClick'}><a onClick={() => { setcolorBg(widgetColor[7]); setcheckIcon(8) }} className="color-7"></a></li>
                              <li className={checkIcon === 9 && 'rightIconClick'}><a onClick={() => { setcolorBg(widgetColor[8]); setcheckIcon(9) }} className="color-8"></a></li>
                              <li className={checkIcon === 10 && 'rightIconClick'}><a onClick={() => { setcolorBg(widgetColor[9]); setcheckIcon(10) }} className="color-9"></a></li>
                           </ul>
                        </div>
                        <button className="btn btn-primary save-btn mt-4" onClick={() => { CreateLiveChat(user.email, name, colorBg) }}>Save & Next</button>

                     </div>
                     <div className="mobile-screen">
                        <div className="mobileFront">
                           <div className="header" style={{ background: colorBg }}>
                              <div className="profileLeft">
                                 <img className="profile"
                                    src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg?w=2000" />
                                 <div className="">
                                    <h6 className="mb-0">Chat</h6>
                                    <p className="mb-0">John Smith</p>
                                 </div>
                              </div>
                              <div className="profileRight">
                                 <span className="dot-menu"></span>
                                 <img src={require('./img/Icon-arrow-down-white.png')} alt="down arrow white" />
                              </div>
                           </div>
                           <div className="headerBottomTab" style={{ background: colorBg }}>
                              <span className="online"></span>
                              <p className="mb-0">We reply immediately</p>
                           </div>
                           <div className="chatMessage">
                              <div className="messageAll">
                                 <div className="recived">
                                    <p>Hello</p>
                                 </div>
                              </div>
                              <div className="messageAll">
                                 <div className="send">
                                    <p style={{ background: colorBg }}>It is a long established fact that a reader will be distracted by the </p>
                                 </div>
                              </div>
                              <div className="messageAll">
                                 <div className="recived">
                                    <p>It is a long established fact that a reader will be distracted by the </p>
                                 </div>
                                 <div className="recived">
                                    <p>It is a long established fact that a reader will be distracted by the </p>
                                 </div>
                              </div>
                           </div>
                           <div className="chatenterMessage">
                              <input type="text" name="" placeholder="Enter your message..." disabled />
                              <div className="msg-fire" style={{ background: colorBg }}>
                                 <img className="sendBtn" src="https://d1c73bf43ozp9u.cloudfront.net/chatbot/wp-content/uploads/2023/02/22102747/Icon-ionic-md-send.png" alt="msg send" />
                              </div>
                           </div>

                           <div className="chatenterAction">
                              <ul>
                                 <li><a href="javascript:void(0)">
                                    <img src='https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/Icon-robot.svg?alt=media&token=985ef278-2d30-4754-8339-52d5c6695f04' alt="robot" /></a><span
                                       className="line"></span></li>
                                 <li><a href="javascript:void(0)"><img src='https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/Icon-attachment.svg?alt=media&token=7f1fce98-c632-4183-b401-6e12a4c12780'
                                    alt="Icon-attachment" /></a>
                                 </li>
                                 <li><a href="javascript:void(0)"><img src='https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/Icon-smile-beam.svg?alt=media&token=736f5748-f98d-47aa-b40a-5c52f7bdc2b6'
                                    alt="Icon-smile-beam" /></a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="widget-view msg-fire" style={{ background: colorBg }}>
                           <img src={widget} alt="msg send" />
                        </div>
                     </div>
                  </div>
               </div>
             {onemore && confirmation ? <div className="botPop aa">
                  <div className="popBody">
                  {!error?<> 
                     <img src="img/congrats.png" alt="congrats" />
                     <h4>Congratulations!</h4>
                     <h6 >You have got 7 days of premium trial for Livechat.</h6>
                     <p className="mb-2" style={{color:'#5D5D5D',fontSize: "15px"}}>Deploy the Livechat widget on your platform and customize it for better user experience.</p>
                   <div className="d-flex mt-4 btn-section justify-content-center">
                        <button className="btn btn-primary" onClick={() => { goBack() }}>Go to Chat Dashboard</button>
                     </div>
                     </>:
            <>
            <h3> Opps!!</h3>
            <p className="mb-2">Something Went Wrong!</p>
            <div className="d-flex mt-4 btn-section justify-content-center">
              <button className="btn btn-primary" onClick={() => {  window.location.reload(); }}>Click here to reload the page</button>
            </div>
            </>}
                  </div>
               </div> : onemore && <div className='botPop'><div className="loaderView"><img src={loader} /></div></div>}

            </div>
         </div>}
      </>)
}

export default Steps2;